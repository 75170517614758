import React from 'react';
import MarketingLayout from '../../components/layout/MarketingLayout';
import ReferralForm from '../../components/referral/ReferralForm';

const ReferralPage: React.SFC = () => (
  <MarketingLayout title="Refer friends to Slab">
    <ReferralForm />
  </MarketingLayout>
);

export default ReferralPage;
