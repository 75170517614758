const FORM_BASE_URL = 'https://getform.io/f/';

export default function submitForm<
  T extends { [s: string]: string | number | File[] | undefined },
>(id: string | undefined, values: T): Promise<Response> {
  if (!id) {
    return Promise.reject(Error('Empty form id'));
  }

  const formData = new FormData();
  Object.entries(values).forEach(([name, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v, i) => formData.append(`${name}[${i}]`, v));
    } else if (value) {
      formData.append(name, String(value));
    }
  });

  return fetch(`${FORM_BASE_URL}${id}`, {
    method: 'POST',
    body: formData,
  }).then((response) =>
    response.ok
      ? response
      : response.text().then((text) => Promise.reject(Error(text))),
  );
}
