import { navigate } from 'gatsby';
import React from 'react';
import referralImage from '../../images/forms/referral.svg';
import submitForm from '../../utils/submitForm';
import Form, { Field, Submit } from '../ui/Form';
import PageSection from '../ui/PageSection';
import Spacing from '../ui/Spacing';
import Text from '../ui/Text';
import styles from './ReferralForm.module.scss';

type ReferralFormValues = {
  yourName: string;
  yourEmail: string;
  friendsName: string;
  friendsEmail: string;
  friendsCompany: string;
};

const handleSubmit = (values: ReferralFormValues) =>
  submitForm<ReferralFormValues>(process.env.FORM_ID_REFERRAL, values).then(
    () => navigate(`${window.location.pathname}/confirmation`),
  );

const ReferralForm: React.SFC = () => {
  const searchParams = new URLSearchParams(
    typeof window !== 'undefined' ? window.location.search : '',
  );

  return (
    <Spacing top={5}>
      <PageSection>
        <div className={styles.container}>
          <div>
            <Text darker>
              <h2>Refer friends to Slab</h2>
            </Text>
            <Spacing top={2}>
              <Text medium muted>
                You get up to $200 in Amazon gift cards per referral, and your
                friend will get 20% off their first 3 months.
              </Text>
            </Spacing>
            <Spacing vertical={4}>
              <img alt="Refer friends to Slab" src={referralImage} />
            </Spacing>
            <Text small>
              <span className={styles.finePrint}>The fine print</span>
            </Text>
            <Text xSmall muted>
              <ul>
                <li>
                  Earn $100 for a referred Startup subscription, $200 for
                  Business
                </li>
                <li>Applicable only for new teams with 5+ paid seats</li>
                <li>Cannot be combined with other offers</li>
                <li>
                  <a
                    href="https://help.slab.com/en/articles/5380446-referral-program"
                    className={styles.link}
                    target="_blank"
                    rel="noreferrer"
                  >
                    See full details here
                  </a>
                </li>
              </ul>
            </Text>
          </div>
          <Form
            initialValues={{
              yourEmail: searchParams.get('email') || '',
              yourName: searchParams.get('name') || '',
              friendsEmail: '',
              friendsName: '',
              friendsCompany: '',
            }}
            onSubmit={handleSubmit}
            validateOnBlur
            validateOnChange
          >
            <Spacing top={2} bottom={2}>
              <Text>
                <h4>Your info</h4>
              </Text>
            </Spacing>
            <Field
              name="yourName"
              label="Full Name"
              type="text"
              required
              placeholder="Monica Hall"
            />
            <Field
              name="yourEmail"
              label="Work Email"
              type="email"
              required
              autoComplete="email"
              placeholder="monica@piedpiper.com"
            />
            <Spacing top={4} bottom={2}>
              <Text>
                <h4>Your friend&apos;s info</h4>
              </Text>
            </Spacing>
            <Field
              name="friendsName"
              label="Full Name"
              type="text"
              required
              placeholder="Gavin Belson"
            />
            <Field
              name="friendsEmail"
              label="Work Email"
              type="email"
              required
              autoComplete="email"
              placeholder="gavin@hooli.com"
            />
            <Field
              name="friendsCompany"
              label="Company"
              type="text"
              required
              placeholder="Hooli"
            />
            <Spacing top={2}>
              <Submit block disableUntilValid color="red">
                Send Referral
              </Submit>
            </Spacing>
          </Form>
        </div>
      </PageSection>
    </Spacing>
  );
};

export default ReferralForm;
